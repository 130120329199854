import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from 'axios'

Vue.config.productionTip = false;

axios.defaults.baseURL = 'https://backend.handasiahgroup.com/api';
// axios.defaults.headers.language = localStorage.getItem('currentLang').toLowerCase();

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
